import React from "react"
import Layout from "../components/layout"
import Page from "../components/page"
import Form from "../components/form"
import Seo from "../components/seo"
import useContentfulImage from "../components/use-contentful-image"
import { graphql } from "gatsby"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

export const query = graphql`
    query {
        page: contentfulContactPage(contactId: {eq: "contactpage"}) {
            title
            addressLine1
            addressLine2
            addressLine3
            town
            city
            postcode
            metaTitle
            metaDescription {
                metaDescription
            }
            mapSource {
              mapSource
            }
            content {
                raw
            }
            bottomContent {
                raw
            }
          }
    }
`;

const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
        const asset = useContentfulImage(node.data.target.sys.id)
            if (asset) {
                return (
                    <img src={asset.node.file.url} alt={asset.node.title} width={asset.node.file.details.image.width} height={asset.node.file.details.image.height} />
                )
            }
        },
    },
}

function Contact({ data: { page } }) {
    return (
        <Layout>
            <Seo
                title={page.metaTitle}
                description={page.metaDescription.metaDescription}
            />

            <Page pageTitle={page.title} pageClass="page-contact">
                {renderRichText(page.content, options)}
            </Page>

            <div className="wrapper padding section-contact">
                <div className="container">
                    <div className="col6">
                        <div className="map-container" id="map">
                            <iframe src={page.mapSource.mapSource} width="600" height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" title="Our location map"></iframe>
                        </div>
                    </div>
                    <div className="col6">
                        <div className="text-inner">
                            <h2>ADDRESS</h2>
                            <p>
                                <strong>{page.addressLine1 ? page.addressLine1 : ''}</strong> <br />
                                {page.addressLine2 ? page.addressLine2 : ''} <br />
                                {page.addressLine3 ? page.addressLine3 : ''} <br />
                                {page.town ? page.town : ''} <br />
                                {page.city ? page.city : ''}, {page.postcode ? page.postcode : ''}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="col10">
                        {renderRichText(page.bottomContent, options)}
                    </div>
                </div>
            </div>

            <Form />
        </Layout>
    );
}

export default Contact
